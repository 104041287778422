import React, { Component } from "react";
import styles from "./stylesheets/contact.module.css";
import logo from "./images/janame_white.png";

class Contact extends Component {
  render() {
    return (
      <div id="contact" className={styles.contact}>
        <img src={logo} alt="Joshua Annenberg" className={styles.image}></img>
        <div className={styles.content}>
          <div className={styles.contact_info}>
            <p>
              <a href="mailto:joshua@annenberglaw.com" target="blank">
                Joshua@AnnenbergLaw.com
              </a>
              <a href="tel:+1-646-872-2040"> | 646-872-2040 |</a>
            </p>
            <p>305 Broadway, Suite 700, New York, NY 10007</p>
          </div>
        </div>
        <p className={styles.copy}>
          © 2019 Noam Annenberg. All rights reserved
        </p>
      </div>
    );
  }
}

export default Contact;
